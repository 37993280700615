.hero {
	height:422px;
	width:100%;
	background-size:cover;
	background-position: 50% 50%;
	padding-top: 160px;
	position:relative;
	z-index: 5;
	padding-bottom: 0em;

	.ribbon {
		background:$overlayTeal;
		color:white;
		padding:30px;
		h1 {
		font-size:em(30);
		color:white;
		}
	}
	&::before {
    left: 0;
    bottom: 0;
    content: '';
    width: 50%;
    position: absolute;
    border-bottom: 13px solid #d4d8d8;
    border-right: 25px solid transparent;box-shadow: inset 0px 8px 12px 0px rgba(0, 0, 0, 0.17);
  }
  &::after {
    bottom: 0;
    left: 50%;
    content: '';
    width: 100%;
    position: absolute;
    border-bottom: 13px solid #d4d8d8;
    border-left: 25px solid transparent;box-shadow: inset 0px 8px 12px 0px rgba(0, 0, 0, 0.17);
  }
}