@mixin trans($prop,$time) {
  @include transition-property($prop);
  @include transition-duration($time);
  @include transition-timing-function(ease-out);
}
@mixin shadowLine($percent, $size, $inset) {
  @if $inset == inset {
    -webkit-box-shadow: inset 0 $size+px $size+px (-$size)+px rgba(0, 0, 0, $percent);
    -moz-box-shadow: inset 0 $size+px $size+px (-$size)+px rgba(0, 0, 0, $percent);
    box-shadow: inset 0 $size+px $size+px (-$size)+px rgba(0, 0, 0, $percent);
  }
  @else {
    -webkit-box-shadow: 0 $size+px $size+px (-$size)+px rgba(0, 0, 0, $percent);
    -moz-box-shadow: 0 $size+px $size+px (-$size)+px rgba(0, 0, 0, $percent);
    box-shadow: 0 $size+px $size+px (-$size)+px rgba(0, 0, 0, $percent);
  }
}

@mixin innerAndOuterShadow(){
  box-shadow:inset 0 5px 5px rgba(0,0,0,.25),  0 5px 5px rgba(0,0,0,.25);

}

@mixin innerShadow(){
   -webkit-box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, .85);
    -moz-box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, .85);
     box-shadow: inset 0 8px 8px -8px rgba(0, 0, 0, .85);
}
@mixin outerShadow(){
   -webkit-box-shadow:  0 8px 8px -8px rgba(0, 0, 0, .85);
    -moz-box-shadow:  0 8px 8px -8px rgba(0, 0, 0, .85);
     box-shadow:  0 8px 8px -8px rgba(0, 0, 0, .85);
}

// Fonts

@mixin akk($weight) {
  @if $weight == 'light' {
    font-family: "Akkurat-Light", sans-serif;
  }
  @else if $weight == 'bold' {
    font-family: "Akkurat-Bold", sans-serif;
  }
  @else {
    font-family: "Akkurat-Regular", sans-serif;
  }
}
@mixin textSection($headlineColor, $textColor, $background, $padding) {
  padding-top: em($padding);
  padding-bottom: em($padding);
  color: $headlineColor;
  background-color: $background;

  @include MQ(maxM) {
    padding-top: em($padding / 2);
    padding-bottom: em($padding / 2);
  }
  h3 {
    @include aaux(black, normal);
    font-size: em(42);
    color: #ffffff;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .25em;

    @include MQ(maxM) {
      font-size: em(32);
      line-height: 1.45em;
    }
  }
  h4 {
    @include aaux(regular, normal);
    font-size: em(24);
    color: $textColor;
    text-transform: uppercase;
    text-align: center;
    padding-top: em(25);
    margin-top: em(15);
    letter-spacing: 2.5px;

    @include MQ(maxM) {
      font-size: em(18);
      padding-left: 8px;
      padding-right: 8px;
    }
    &::before {
      // width, height, bgColor
      @include dash(25px, 3px, $textColor);
      top: 0;
    }
  }
}
@mixin closeX($size, $background, $thickness){

  $size: 20px;
  height: $size;
  width: $size;
  position: relative;
  box-sizing: border-box;
  line-height: $size;
  display: inline-block;

  &:before, &:after {
    $height: $thickness;
    transform: rotate(-45deg);
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -$height/2;
    margin-left: -$size/2;
    display: block;
    height: $height;
    width: $size;
    background-color: $background;
    transition: all 0.2s ease-out;
  }
  &:after {
    transform: rotate(45deg);
  }

}


