
#cowBody {
	margin-top: 45px;
	svg {
		width: 100%;
		.st1 {
			fill: #D5D7D7;
		}

		.st3 {
			fill: #4c7380;
			stroke-width: 0;
			margin-right: 8px;
			pointer-events: none;
		}
		.part {
			cursor: pointer;
			@include transition(all .2s ease-out);
			&:hover,
			&.activeState {
				&.shape {
					fill: #4c7380;
				}
				& ~ .st3
				& .st3 {
					fill: #fff!important;
					@include transition(all .1s ease-out);
				}
			}
		}

		// animation / toggles
		opacity: 1;
		height: auto;
		@include transition(all .2s ease-out);
		&.hidden {
			opacity: 0;
			height: 0;
		}
	}
}

.shape:hover ~ .text .st3,
.shape:hover ~ rect ~ text .st3,
.shape:hover ~ rect ~ text.st3,
.shape.activeState ~ .text .st3,
.shape.activeState ~ rect ~ text .st3,
.shape.activeState ~ rect ~ text.st3 {
	fill: #fff!important;
	stroke-width: 0;
}


.beefCutDescription {
	display: none;
	margin-top: 35px;

	img {
		width: 100%;

		@include MQ(maxM) {
			margin-bottom: 15px;
		}
	}
	&.activeState {
		display: inline-block;

		&:nth-of-type(2n+1) {
			clear: both;
		}
	}
}

.cow-buttons {
	@include MQ(ML) {
		height: 500px;
		display: flex;
		align-items: center;
		padding-left: 60px;
	}
	a {
		margin: 0 auto;
		display: block;
		width: 250px;
		clear: left;
		margin-bottom: 20px;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.varietyButtons {
	.col-lg-3 {
		padding: 0 2.5px;
	}

	a.btn {
		background-color: #D5D7D7;
		color: $teal;
		display: block;
		@include MQ(maxM) {
			font-size: .8em;
		}

		margin: 0 5px 5px;
		padding: 6px 25px;
		text-align: center;
		width: 100%;

		&.activeState {
			background-color: $teal;
			color: #D5D7D7;
		}

	}
}