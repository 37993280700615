$icon-font-path: '../fonts/';
@import "bourbon/bourbon";
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "mediaQ.scss";
@import "colors.scss";
@import "mixins/mixins.scss";
@import "font.scss";
@import "button.scss";
@import "hero.scss";
@import "nav.scss";
@import "callouts.scss";
@import "footer.scss";
@import "_twoColumnList.scss";
// Pages
@import "home.scss";
@import "about.scss";
@import "products.scss";
@import "b2b.scss";
@import "beefCuts.scss";
@import "contact.scss";

// fonts
.regular {
	font-family: 'Neue Helvetica W01', helvetica, sans-serif;
	font-style: normal;
	font-weight: 400;
}
.bold {
	font-family: 'Neue Helvetica W01', helvetica, sans-serif;
	font-style: normal;
	font-weight: 700;
}
html {
	width: 100%;
	min-width: 320px;
}
body {
	font-size: 16px;
	overflow-x: hidden;
	background: white;
	color: $bodyGrey;
	height: 100%;
	font-family: 'Neue Helvetica W01', helvetica, sans-serif;
	font-style: normal;
	font-weight: 400;
	min-width: 320px;

	@include MQ(S) {
		padding-bottom: 0;
	}
}
body.footer-open {
	padding-bottom: 340px;
}
a {
	color: white;
	text-decoration: none;
	font-weight:700;
	text-decoration:underline;
	&:hover, &:active, &:focus {
		color: darken(white, 15%);
		text-decoration: underline;
	}
}
h1, h2, h3, h4, h5, h6 {
	font-weight: normal;
	font-style: normal;
	margin: 0;
	padding: 0;
	color: $teal;
	font-family: 'Neue Helvetica W01', helvetica, sans-serif;
	font-style: normal;
	font-weight: 700;
}
sup {
	color: inherit;
	top: -0.2em;
}
h1 {
}
h2 {
	font-size: em(38);
}
h3 {
	font-size: em(28);
	line-height: 1.4em;
	position: relative;
	@include MQ(maxM) {
		font-size: em(23)
	}

	.arrow {
		height: .8em;
		bottom: .15em;
		right: -9em;
		display: block;
		position: absolute;
		padding-left: 10px;
		margin: auto;
		@include transition(all .15s ease-out);

		svg {
			width: 100%;
			height: 100%;
			display: block;
			fill: white;
		}
		.st1 {
			fill: rgba(255,255,255,.5);
		}

	}
	&:hover {
		.arrow {
			padding-left: 12px;

			svg {
				path {
					opacity: 1;
				}
			}
			.st1 {
				@include transition(all .15s ease-out);
				fill: rgba(255,255,255,1);
			}
		}
	}

}
h4 {
	font-size: 1.3em;
	line-height: 1.4em;
}
h5 {
	font-size: 1.2em;
	line-height: 1.3em;
}
h6 {
	font-size: 1.1em;
	line-height: 1.3em;
}
p {
	font-size: 1em;
	line-height: 1.4em;
	margin-top: .7em;
	color: $bodyGrey;

	a {
		color: $teal;

		&:hover {
			color: $darkTeal;
			text-decoration: underline;
		}
	}
	strong {
		text-transform: uppercase;
	}
}
sup {
	font-size: 55%;
	top: -0.6em;
}
img {
	max-width: 100%;
}
.clear {
	clear: both;
}
.larger {
	font-size: 110%;
}
.smaller {
	font-size: 80%;
}
.super_small {
	font-size: 70%;
	line-height: 1em;
}
.nowrap {
	display: inline-block;
}
//helpers

.noPadding {
	padding: 0;
}
.noPaddingDesktop {
	padding-left: 0;
	padding-right: 0;

	.row {
		margin: 0;
	}
	@include MQ(maxM) {
		padding-left: 15px;
		padding-right: 15px;

		.row {
			margin-left: -15px;
			margin-right: -15px;
		}
	}
}
.center-block {
	float: none;
}

#inner-wrap {
	position: relative;
	z-index: 0;
}

section {
	padding-top: 2.5em;
	padding-bottom: 2.5em;
	position: relative;
	z-index: 1;
	background: white;

	@include MQ(maxM) {
		padding-top: 2em;
		padding-bottom: 2em;
	}
	&.gradient {
		@include linear-gradient(#ffffff, #d2d6d6);
	}
	&.gradient-alt {
		@include linear-gradient(#d6dada 0%, #fff 10%);
	}
	&.blueGradient {
		@include linear-gradient(#1c505c 10%, #4c7380 40%, #4c7380 60%, #396572 90%);
		hr {
			background: $darkTeal;
			border: 0;
			border-bottom: 1px solid $darkTeal;
			margin: 2em 0;
		}
	}
	&.blueGradient-2 {
		@include linear-gradient(#05303a 10%, #0e3844 40%, #0e3844 60%, #05303a 90%);
	}
	&.blueGradient-3 {
		background: #4b7380;
	}

}
// Shadow for sections
.shadow {
	box-shadow: inset 0px 25px 50px 0px #d4d8d8;
}



// Full Image section
.fullImage {
	padding: 0;

	.container-fluid {
		padding-left: 0;
		padding-right: 0;
	}
	img {
		width: 100%;
	}
}
.equalHeights {
	@include MQ(M) {
		display: table;
		width: 100%;
		position: relative;
		// left: -.5%;

		.topColumns {
			display: table-cell;
			float: none;
			vertical-align: top;
		}
	}
}
.inputForm {
	.form-group {
		margin-bottom: 0;

		&.has-error {
			input {
				box-shadow: 0 0 2px $red;
			}
		}
	}
	input {
		background: $lightGrey;
		border: none;
		padding: 8px 20px;
		width: 70%;
		height: 50px;
		margin: 0;
		float: left;
	}
	.btn {
		height: 50px;
		padding: 8px 20px;
		width: 30%;
		margin: 0;
		float: left;
	}
}
a.btn-dark {
	background: #0e3844;
	color: #7e959b;
}
// pods
.pod-container {
	padding: 3em 2em;

	&.two-column {
		.pod {
			&:nth-child(2n + 1) {
				clear: both;
			}
		}
	}
	.pod {
		margin-bottom: 2em;
		padding-right: 2em;

		@include MQ(maxM) {
			// text-align: center;

			&:not(:first-child) {
				margin-top: 1em;
			}
		}
		p {
			&:first-child {
				margin-top: 0;
			}
		}
		ul {
			margin-top: 15px;
			padding-left: 25px;
			text-align: left;

			li {
				color: $bodyGrey;
				margin: .5em 0;
				font-size: 1em;
			}
		}
		img {
			margin: 0 auto 1.5em auto;
			display: block;

			@include MQ(M) {
				width: 100%;
				margin:auto;
			}
		}
	}
}

.thankYou {
  display:none;
  margin-top: 35px;
  p {
    color:white;
  }
  h1 {
    color:white;
    text-transform:uppercase;
  }

}

$base-line-height: 24px;
$white: $lightGrey;
$off-white: rgba($white, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

.loading-pulse {
	position: relative;
	width: 6px;
	height: 10px;
	background: $off-white;
	animation: pulse $pulse-duration infinite;
	animation-delay: ($pulse-duration / 3);
	top: -1px;
	margin-left: 15px;
	margin-right: 15px;
	display: inline-block;

	&:before, &:after {
		content: '';
		position: absolute;
		display: block;
		height: ($base-line-height / 1.5);
		width: ($base-line-height / 4);
		background: $off-white;
		top: 50%;
		transform: translateY(-50%);
		animation: pulse $pulse-duration infinite;
	}
	&:before {
		left: -($base-line-height / 2);
	}
	&:after {
		left: ($base-line-height / 2);
		animation-delay: ($pulse-duration / 1.5);
	}
}
@keyframes pulse {
	50% {
		background: $white;
	}
}

.centerBlock {
	text-align: center;
	padding: 0px 15%;
}

.flex-cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;

	&.flex-cards-small {
		a {
			height: 243px;
			width: 262px;
			outline: 1px solid #0e3844;
			transition: .1s;

			&:hover {
				outline: 2px solid #0e3844;
			}
			.imgWrap {
				height: 184px;
			}
			.textWrap { padding: 20px; }
			h3 { font-size: 16px; }
			p {
				display: none;
			}
			&:nth-of-type(even) {
				background: #0e3844;
				.imgWrap:after  {
					border-color: transparent transparent #0e3844 transparent;
				}
			}
		}
	}
	a {
		display: block;
		text-decoration: none;
		background: #0e3844;
		margin: 10px auto;
		width: 350px;
		height: 520px;
		@include MQ(maxM) {
			width: 90%;
			height: auto;
		}
		overflow: hidden;
		position: relative;
		text-align: left;
		outline: 1px solid #fff;
		transition: .3s;
		// Light Blue Card color - every other card
		&:nth-of-type(even) {
			background: #4b7380;
			&.flex-cards-small {
				background: initial;
			}
			.imgWrap:after  {
				border-color: transparent transparent #4b7380 transparent;
			}
		}
		.imgWrap {
			height: auto;
			@include MQ(S) {
				height: 325px;
			}
			@include MQ(M) {
				height: 245px;
			}
			overflow: hidden;
			position: relative;
			img { width: 100%; }
			&:after {
				position: absolute;
				content: "";
				display: block;
				bottom: 0;
				left: 40px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 20px 20px 20px;
				border-color: transparent transparent #0e3844 transparent;
				.flex-cards a.altColorCard .imgWrap:after  {
					border-color: transparent transparent #4b7380 transparent;
				}
			}
		}
		.textWrap {
			padding: 30px;
		}
		h3 {
			margin-top: 20px;
			// padding: 10px;
			color: white;
			line-height: 1;
			font-weight: 700;
			font-size: 21px;
			display: inline;
			position: relative;

		}
		&:hover {
			outline: 1px solid #0e3844;

		}
		p {
			// margin: 0 10px;
			color: white;
			font-weight: normal;
		}
	}
}

.white-bar {
	text-align: center;
	padding-top: 0;
	padding-bottom: 5em;
}

