.contact {
    .hero {
        background: url('../images/contact/hero.jpg') 50% 10% no-repeat;
        background-size: cover;
        height: 400px;
        @include MQ(maxM) {
            display:none;
        }
    }
    h3 {
        font-size: 2em;
    }
    .btn {
        margin-top: 1em;
        padding-top: 10.5px;
        padding-bottom: 10.5px;
        width: 100%;
        text-align: center;
        &.valid {
            background:green;
        }
    }

    .contactContainer {
        position:relative;
        z-index:0;
        .thankYou {
            display:none;
        }
    }
    #footer .col-sm-6 {
        display: none;
    }
}

#contactForm {
    padding: 0 20px;
}

.radioGroup {
    input[type="radio"] {
        width: 18px;
        height: 18px;
        bottom: -2px;
        position: relative;
    }
    > div {
        width: 50%;
        @include MQ(ML){
            float:left;
                padding: 0 0 0 12%;
            &:last-of-type {
                border-left: 2px solid #4b7380;
            }
        }
        label {
            font-size: 1.2em;
            color: #ddd;
            margin-left: 10px;
        }
    }
}

.form-group {
    position: relative;

    &:before {
        // content: '';
        // height: 7px;
        // width: 100%;
        // position: absolute;
        // top: -7px;
        // left: 0;
        // background: url('../images/contact/formLines.png') 0 0 repeat-x;
    }
    .form-control {
        height: 40px;
        font-size: 14px;
    }
    input, textarea {
        color: black;
        border-radius: 0;
        text-align: left;
        padding: 10px 15px;
        width: 100%;
        border: none;
        margin: 25px 0 22px 0;
    }
    textarea {
        min-height: 170px;
        padding: 15px 15px;
    }
    label {
        position: absolute;
        top: 21px;
        left: 0;
        right: 0;
        margin: auto;
        text-align: left;
        margin-left: 15px;
        font-size: 1em;
        opacity: 1;
        color: $grey;
        vertical-align: middle;
        @include transform(translate(0, -50%))
        font-weight: 400;
        @include transition(opacity .15s ease-out);
    }
    &.has-error {
        margin-bottom: 45px;
        @include MQ(maxM){
            margin-bottom: 60px;
        }
        input, textarea {
            box-shadow: 0 0 5px red;
            outline: none;
        }
        .help-block {
            position: absolute;
            bottom: -40px;
            @include MQ(maxM){
                bottom:-45px;
            }
        }
    }
    &.filled {
        label {
            opacity: 0;
        }
        input, textarea {
            box-shadow: 0 0 5px $red;
            outline: none;
        }
        &.has-success {
            box-shadow: 0 0 5px #27e833;
        }
        &.has-error {
            box-shadow: 0 0 8px red;
        }
    }
}

// Custom select drop down and contact page
$base-line-height: 24px;
$white: rgb(255,255,255);
$off-white: rgba($white, 0.2);
$spin-duration: 1s;
$pulse-duration: 750ms;

.loading-pulse {
  position: relative;
  width: 6px;
  height: 10px;
  background: $off-white;
  animation: pulse $pulse-duration infinite;
  animation-delay: ($pulse-duration / 3);
  top:-1px;
  margin-left: 15px;
  margin-right: 15px;
  display:inline-block;
  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
    height: ($base-line-height / 1.5);
    width: ($base-line-height / 4);
    background: $off-white;
    top: 50%;
    transform: translateY(-50%);
    animation: pulse $pulse-duration infinite;
}
&:before {
    left: -($base-line-height / 2);
}
&:after {
    left: ($base-line-height / 2);
    animation-delay: ($pulse-duration / 1.5);
}
}

@keyframes pulse {
  50% {
    background: $white;
}
}