.two-column-list {
	margin-top: 2.5em;
	margin-bottom: 2.5em;
	padding: 0 10%;
	.listItem {
		img {
			float: right;
		}
		position: relative;
		overflow: hidden;
		padding-right: 20px;
		&:first-child {
			.item {
				padding-top: 0;
			}
		}
		&:last-child{
			.item {
				&::after {
					display:none;
				}
			}
		}
		.item {
			padding-bottom: 2em;
			padding-top: 2em;
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 15px;
				width: calc(100% - 15px);
				height: 1px;
				background-color: $darkTeal;
			}
		}
	}
}

.two-column-list-bar {
	margin: 0;
}