.home {
	.hero {
		background-image:url('../images/home/hero.jpg');
		
	}
	.homeInfo {
		h3 {
			margin-top: 1.5em;
			&:first-child {
				margin-top: 0;
			}
		}
		img {
			max-width:100%;
			@include MQ(maxM) {
				display:none;
			}
		}
	}
	
	
}