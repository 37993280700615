$navSpacing: 35px;

nav {
  border: none;
  box-shadow: none;
  position: relative;
  z-index: 999;
  height:93px;
  margin-bottom: 0;
  background: white;
  @include MQ(ML) {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15)
  }
  @include MQ(maxML) {
    height: 55px;
  }
  $speed:200ms;
  $easing: cubic-bezier(.17,.67,.85,1.15);

  .btn {
    font-size:14px;
    font-family:'Neue Helvetica W01', helvetica, sans-serif;
    font-style: normal;
    font-weight: 400;
    padding:9px 15px;
  }

  .navbar-toggle {
    overflow: hidden;

    @include MQ(maxML) {
      float: left;
      background: $teal;
      height: 100%;
      margin-top: 0;
      border-radius: 0;
      padding: 10px 15px;
    }
    .icon-bar {
      transition: opacity, transform;
      transition-duration: $speed;
      transition-timing-function: $easing;
      background: white;
      //padding: 0 25px;
    }
    &:not(.collapsed) {
      .icon-bar {
        &:nth-child(1) {
          transform: translateY(6px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
          transform: translateX(-100%);
        }
        &:nth-child(3) {
          transform: translateY(-6px) rotate(-45deg);
        }
      }
    }
  }
  .dropdown:hover .dropdown-menu {
    @include MQ(ML) {
      // @include transform(translate(0, 0));
      height: 210px;
      left: 10;
      li {
        opacity: 1;
        transition: 1s;
      }
    }
  }
  &.navbar {
    margin-bottom: 0;
    border: none;
    padding-top: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    position: absolute;
    width: 100%;
    z-index: 5;
    background: rgba(255,255,255,.9);
    @include MQ(maxML) {
      padding-top: 0;
    }
  }
  .navbar-header {
    display: inline-block;
    @include MQ(maxML) {
      width: 100%;
      background: white;
      position: relative;
      height: 60px;
      overflow: hidden;
      display: block;
    }
  }
  .navbar-collapse {
    border: none;
    box-shadow: none;

    @include MQ(maxML) {
      padding: 0;
    }
  }
  .navbar-brand {
    height: auto;
    padding: 0;
    z-index: 5;
    width: 100%;
    position: relative;

    img {
      width: 150px;
      padding-top: 10px;
    }
    @include MQ(ML) {
      padding: 0 40px 0 80px;
      width: auto;
      height: 60px;
    }
    @include MQ(ML) {
      // left: auto;
    }
    @include MQ(maxNavL) {
      padding: 0 20px;
    }
    @include MQ(maxML) {
      position: absolute;
      left: 0;
      right: 0;
      margin: auto;
      width: auto;
      padding: 0;
      width: 110px;

      img {
        width: 100%;
        padding-top: 17px;
      }
    }
  }
  .navbar-nav {
    position: relative;
    text-align: center;
    padding: 0 10px;

    .rightNav {
      position:absolute;
      top:-5px;
      right:0;
      margin-right:25px;
      width:230px;
      text-align:left;
      font-size:14px;
      @include MQ(maxMLL){
        margin-right: 10px;
      }
    }

    @include MQ(maxML) {
      position: relative;
      background: white;
      margin-top: 0;
      top: 0;
      overflow: hidden;
      width: 100%;
      left: 0;
      margin: 0;
      padding: 0;
      text-align: left;
      z-index: 99999;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
      border-bottom: 2px solid $grey;
    }

    li {
      position: relative;
      color:$teal;
      @include MQ(ML){
        &.current {
          color: white;
          a {
            // color:white;
            &:after {
              opacity: 1;
              // display: none;
            }
          }
        }
      }
      ul {
        text-align: left;
        padding-left: 0;
        li {
          display: block;
          list-style: none;
          margin: 0;

          @include MQ(ML) {
            &:first-of-type {
              margin-top: 20px;
            }
          }
          padding: 0;
          opacity: 0;
          transition: 1s;
          position: relative;
          z-index: 6;
          a {
            font-size: 18px;
            text-align: left;
            display: block;
            font-weight: 300;
            padding: 10px 20px 10px 40px;
            @include MQ(ML) {
              padding: 8px 20px 8px 20px;
            }
            &:hover {
              background: $teal;
              color: white;
            }
            &:after {
              display: none;
            }
          }
        }
      }

      @include MQ(ML) {
        padding: 0;
        display: inline-block;
      }
      @include MQ(maxML) {
        width: 100%;
        float: left;
        padding: 0;
        border-top: 1px solid #eee;

      }
      &:last-child {
        @include MQ(maxML) {
        // padding-bottom: 18px;
      }
    }
    &:last-child {
      margin-right: 0;

      @include MQ(ML) {
        &:after {
          display: none
        }
      }
      a {
        border: none
      }
    }
    a {
      color: $teal;
      display: block;
      padding: 15px;
      font-size: 22px;
      text-align: left;
      position: relative;
      font-weight: 300;
      text-decoration:none;
      @include trans(transform, 0.15s);

      &:hover, &:active {
        color: #fff;
        background-color: $teal;
      }
      @include MQ(ML) {
        color: $teal;
        background: transparent;
        font-size: 16px;
        text-align: left;
        position: relative;
        font-size: 15px;
        text-align: center;
        padding: 8px 10px;
        padding-top: 27px;
        &:hover, &:active, &:focus {
          background: transparent;
          color: $teal;
          // @include transform(translate(0, -2px));
        }
      }
      @include MQ(ML) {
        padding: 25px 30px 23px;
        font-size: 18px;
        &:after {
          content: "";
          position: absolute;
          bottom: 8px;
          left: 0;
          background: $teal;
          height: 2px;
          display: block;
          z-index: 2;
          width: 70%;
          margin: 0 25px;
          opacity: 0;
          transition: .5s ease;
        }
        &:hover:after {
          opacity: 1;
          // bottom: 8px;
        }
      }
    }
  }


  .caret {
    position: relative;
    top: -2px;

    @include MQ(maxML) {
      display: none;
    }
  }
  .dropdown {
    @include MQ(ML) {
      > a {
        padding-top: 23px;
        padding-bottom: 29px;
        &:after {
          bottom: 17px;
        }
      }
    }
  }
  .dropdown-toggle {
    &:hover, &:focus, &:active {
      // background: transparent !important;
      // color: inherit;
    }
  }

  .dropdown-menu {
    @include MQ(ML) {
      height: 0;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2) inset;
    }

    li {
      opacity: 1;
      transition: 1s;
      @include MQ(ML) {
        opacity: 1;
      }
    }
    padding: 0;
    margin: 0;
    transition: .4s ease-in-out;
    border: 0;
    overflow: hidden;
    background: white;
    padding: 0 1000em;
    margin: 0 -1000em;
    min-width: 175px;
    position: absolute;
    z-index: 4;
    display: block;

    @include MQ(maxMLL) {
      min-width: 160px;
    }
    @include MQ(maxML) {
      display: block;
      position: relative;
      float: left;
      width: 100%;
      padding: 0;
      margin: 0;
    }
    .background {
      background: $lightGrey;
      padding: 15px 15px 12px 15px;
      position: relative;
      top: -20px;
      text-align: center;
      // box-shadow: inset 0px 4px 4px 0px rgba(0, 0, 0, 0.1), 0px 2px 2px 0px rgba(0, 0, 0, 0.05);

      @include MQ(maxML) {
        background: transparent;
        width: 100%;
        left: 0;
        padding: 0;
        top: 0;
      }
      li {
        padding: 0 0 10px 10px;
        text-align: left;
        width: 100%;
        &.current {
          a {
            color:$grey;
            &:hover {
              color:$grey;
            }
          }
        }
        @include MQ(maxML) {
          padding: 20px 0 0 0;
          text-align: left;
          padding-bottom: 0;

          &:after {
              // display: none;
            }
          }
          a {
            padding: 0;
            font-size: 18px;
            text-transform: uppercase;
            color: white;

            @include MQ(maxMLL) {
              font-size: 15px;
            }
            &:after {
              display: none;
            }
            &:hover {
              color: $grey;
              text-decoration: none;
            }
            @include MQ(maxML) {
              color: darken($lightGrey, 10%);
              text-transform: uppercase;
              font-size: 20px;
              padding: 0 0 0 45px;
              text-align: left;
              display: block;
              width: 100%;

              &:hover {
                text-decoration: none;
              }
            }
          }
        }
      }
    }
  }
  .language {
    background: $lightGrey;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-top: 18px;
    padding-left: 5px;
    padding-right: 5px;
    @media print {
      display:none;
    }
    // Temp hidden until we get languages
    display:none;
    @include MQ(maxNavL) {
      background: transparent;
      top: 55px;
      right: 15px;

      a {
        font-size: 14px;
      }
    }
    @include MQ(maxML) {
      display: none;
    }
    a {
      padding: 0 10px;
      position: relative;

      &:hover {
        color: white;
      }
      &:first-child {
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 3px;
          height: 100%;
          width: 1px;
          background: white;
        }
      }
    }
  }
  .icon-bar {
    background: white
  }
}