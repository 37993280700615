.products {
	.hero {
		background:url('../images/products/hero.jpg') 50% 10% no-repeat;
		background-size:cover;
		height: 400px;
	}
	.products-cards {
		margin: 0 auto;
		text-align: center;
		@include MQ(S) {
			max-width: 725px;
		}
	}
}

.boxed-beef {
	.hero {
		background:url('../images/products/hero-cuts.jpg') 50% 10% no-repeat;
		background-size:cover;
		height:400px;
	}
}

.variety-meats {
	.hero {
		background:url('../images/products/hero-meats.jpg') 50% 10% no-repeat;
		background-size:cover;
		height:400px;
	}
}

.ground-beef {
	.hero {
		background:url('../images/products/hero-beef.jpg') 50% 50% no-repeat;
		background-size:cover;
		height:400px;
	}
}

#sellFormBlock {
	.btn {
		display: block;
		padding: 8px 25px;
	}
}

#sellButton {
	margin: 0 20px;
}