@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon.eot?hvqirm');
    src:    url('../fonts/icomoon.eot?hvqirm#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.ttf?hvqirm') format('truetype'),
        url('../fonts/icomoon.woff?hvqirm') format('woff'),
        url('../fonts/icomoon.svg?hvqirm#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  src: local('News Cycle'), local('NewsCycle'), url(https://fonts.gstatic.com/s/newscycle/v13/d03oiboZGiaNuMDvH253CgsYbbCjybiHxArTLjt7FRU.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 400;
  src: local('News Cycle'), local('NewsCycle'), url(https://fonts.gstatic.com/s/newscycle/v13/9Xe8dq6pQDsPyVH2D3tMQgzyDMXhdD8sAj6OAJTFsBI.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  src: local('News Cycle Bold'), local('NewsCycle-Bold'), url(https://fonts.gstatic.com/s/newscycle/v13/G28Ny31cr5orMqEQy6ljtzrEaqfC9P2pvLXik1Kbr9s.woff2) format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'News Cycle';
  font-style: normal;
  font-weight: 700;
  src: local('News Cycle Bold'), local('NewsCycle-Bold'), url(https://fonts.gstatic.com/s/newscycle/v13/G28Ny31cr5orMqEQy6ljt2aVI6zN22yiurzcBKxPjFE.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-printer {
    cursor: pointer;
}
.icon-printer:before {
    content: "\e954";
}
.icon-mail2:before {
    content: "\ea84";
}
.icon-facebook2:before {
    content: "\ea91";
}
.icon-instagram:before {
    content: "\ea92";
}
.icon-twitter:before {
    content: "\ea96";
}
.icon-pinterest:before {
    content: "\ead1";
}
