.about {
	.hero {
		background:url('../images/About/about-hero.png') 50% 85% no-repeat;
		background-size:cover;
		height:400px;
	}
	.pod {
		position:relative;
		&:hover {
			.chefDescription, p {
				opacity:1;
			}
		}
		.podImage {
			position: relative;

		}
	}
	.mapSection {
		padding: 0;
		height: 100%;
		.col-sm-6 {
			padding: 0;
			img {
				width: 100%;
				border-top: 1px solid #fff;
				@include MQ(M) {
					border-top: 0;
					border-left: 1px solid #fff;
				}
			}
			&:first-of-type {
				div {
					p {
						color: white;
					}
					margin: 10%;
					@include MQ(M) {
						margin-left: 40%;
					}
				}
			}
		}
	}
}

.logoBlock {
	margin-top: 60px;
	margin-bottom: 50px;
	a {
		display: inline-block;
		width: 160px;
		height: 160px;
		background:url('../images/About/logos/logos-sprite.png') no-repeat;
		&:hover {
			background-position-y: 100%;
		}
		&.logo-cargill 			{ background-position-x: calc(0*-160px); }
		&.logo-love 			{ background-position-x: calc(1*-160px); }
		&.logo-todays-kitchen   { background-position-x: calc(2*-160px); }
		&.logo-sterling 		{ background-position-x: calc(3*-160px); }
		&.logo-angus 			{ background-position-x: calc(4*-160px); }
		&.logo-blackwell 		{ background-position-x: calc(5*-160px); }
		&.logo-pasture 			{ background-position-x: calc(6*-160px); }
		&.logo-texas 			{ background-position-x: calc(7*-160px); }
		&.logo-rumba 			{ background-position-x: calc(8*-160px); }
		&.logo-ground 			{ background-position-x: calc(9*-160px); }
		&.logo-meating 			{ background-position-x: calc(10*-160px); }
		&.logo-board 			{ background-position-x: calc(11*-160px); }
		&.logo-beef 			{ background-position-x: calc(12*-160px); }
		&.logo-ncba 			{ background-position-x: calc(13*-160px); }
		&.logo-usda 			{ background-position-x: calc(14*-160px); }
	}
}