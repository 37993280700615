footer {
	padding-bottom: 3em;
	position: relative;
	p {
		margin-top: 2em;
		color: $grey;
		font-size:.85em;
		a {
			color:$bodyGrey;
			font-weight: 700;
		}
	}
}