/// Buttons ////
.btn {
	cursor: pointer;
	background: $teal;
	&.darker {
		background: #0e3844;
	}
	color: $lightGrey;
	font-size: em(22);
	border-radius: 0;
	padding: 9px 50px 9px 25px;
	margin-top: .5em;
	display: inline-block;
	position: relative;
	@include transition(all .15s ease-out);
	text-decoration: none;
	overflow: hidden;
	font-family: 'Neue Helvetica W01', helvetica, sans-serif;
	font-style: normal;
	font-weight: 700;

	&:hover, &.active {
		background: $darkTeal;
		text-decoration: none;
		color: white;

		.arrow {
			right: 18px;

			svg {
				path {
					opacity: 1;
				}
			}
			.st1 {
				@include transition(all .15s ease-out);
				fill: rgba(255,255,255,1);
			}
		}
	}
	&:active {
		@include transform(scale(.98));
	}
	&:focus {
		text-decoration: none;
	}
	&.white {
		background: white;
		color: $red;

		&:hover {
			text-decoration: none;
			background: $red;
			color: white;
		}
	}
	.arrow {
		position: absolute;
		height: 35%;
		width: 7%;
		top: 0%;
		bottom: -2px;
		right: 20px;
		margin: auto;
		@include transition(all .15s ease-out);

		svg {
			width: 100%;
			height: 100%;
			display: block;
			fill: white;
		}
		.st1 {
			fill: rgba(255,255,255,.5);
		}

	}
}