// Brand colors

// Teals
$teal: #4c7380;
$darkTeal: #1b4f5b;
$greyTeal: #a5b9c0;
$overlayTeal: rgba(27, 79, 91, .85);
// Greys
$bodyGrey: #808080;
$lightGrey: #898d8e;
$red: #b6c1c7;
$grey: #949899;
$lightGrey: #dbdfdf;
$tan: #dbd6cf;

.text-red {
	color: $red;
}
.text-grey {
	h1, h2, h3, h4, h5, p {
		color: $grey;
	}
}
.text-white {
	color: white;

	h1, h2, h3, h4, h5, p, a {
		color: white;
	}
	a:hover {
		color:white;
	}
}