
.cta-bar {
  padding: 60px 0;
  text-align: center;
  background: #0e3844;
  border-bottom: 1px solid white;
  h3 {
    color: white;
    margin-bottom: 10px;
  }
  a {
    color:#ccc;
  }
}

.blue-links-bar {
  padding: 0;

  .col-sm-6 {
    background: $darkTeal;
    text-align: center;
    padding-top: .75em;
    padding-bottom: .75em;
    display:block;
    @include MQ(maxM) {
      padding-top: 0;
      padding-bottom: 0;
      margin-top:0;
    }
    &:hover {
      .btn {
        color: white;

        .arrow {
          right: 18px;

          svg {
            path {
              opacity: 1;
            }
          }
        }
      }
    }
    &:active {
      .btn {
        @include transform(scale(.98));
      }
    }
    &:first-child {
      background: $teal;
      border-right: 1px solid #aaa;
    }
    .btn {
      background: none;
      padding-top: .5em;
      padding-bottom: .5em;
    }
  }
}